<template>
  <div class="row px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Carriers
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 350 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="list-table-container">
            <ul class="settings-table list-table p-0">
              <li class="list-table-row list-table-header">
                <ul>
                  <li class="list-table-column">Provider</li>
                  <li class="list-table-column">Number</li>
                  <li class="list-table-column">Description</li>
                </ul>
              </li>
              <hr />
              <li class="list-table-row">
                <ul>
                  <li class="list-table-column">Telus</li>
                  <li class="list-table-column">
                    {{ "17512741" | phoneNumber }}
                  </li>
                  <li class="list-table-column">
                    Integrated account for all team members.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: ""
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.calculateHeight();
      }
    } else {
      this.$router.push("/");
    }
  },
  afterMounted() {
    this.calculateHeight();
  },
  calculateHeight() {
    this.height_card = this.$refs.card.clientHeight;
  }
};
</script>
